.video-player-container {
  min-height: 100vh;
}

.video-info-list {
  list-style: none;
  padding: 0;
}

.video-item {
  border: 1px solid #ddd;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 5px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h2,
h4 {
  color: #333;
}

p {
  color: #555;
}

.description-section {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

@media (max-width: 576px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  h1.h3 {
    font-size: 1.5rem;
  }

  .p-4 {
    padding: 1rem !important;
  }
}

@media (min-width: 577px) and (max-width: 991px) {
  h1.h3 {
    font-size: 1.75rem;
  }
}

/* Анімації */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
